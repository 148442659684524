import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../_services/global-context";
import Api from "../_services/api_service";
import URL from "../_services/routes";
import PrintTickets from "./print_tickets";
import Services from "../_services/services";

export default function PrintTicketsView() {
    const [global, dispatch] = useContext(GlobalContext);
    const [printContent, setPrintContent] = useState('');
    const [processing, setProcessing] = useState(false);
    const componentRef = useRef();

    const order_id = URL.urlQueryParams('order-id')
    const access_token = URL.urlQueryParams('access-token')
    useEffect(() => {
        getTicketsPrintContent()
    }, [order_id]);

    const getTicketsPrintContent = () => {
        const params = {
            pcl_id: global.pcl.id,
            print_type: 'all',
        };

        if (order_id) params['order_id'] = order_id;
        if (access_token)  params['access_token'] = access_token;

        setProcessing(true)
        const qryParams = '?' + new URLSearchParams(params).toString();
        Api.get(
            `client/prints${qryParams}`,
            (res) => {
                const data = res.data.data;
                if (data?.print_content) {
                    setPrintContent(data.print_content);
                }

                setProcessing(false)
            }, (errors) => {
                setProcessing(false)
                dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
            }
        );
    };

    return (
        <div>
            {printContent && (
                <PrintTickets printContent={printContent} />
            )}

            {(!printContent && !processing)&&
                <div className='fs-22x fw-bold text-center mt-4'>Sorry printing details not found.</div>
            }

            <div dangerouslySetInnerHTML={{__html: printContent}}></div>
        </div>
    );
}
