import React, {useRef} from "react";
import {useReactToPrint} from "react-to-print";

export default function PrintTickets({printContent}) {

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        contentRef: componentRef,
    });

    return (
        <div>
            {(printContent) &&
                <div className='d-flex justify-content-around p-2 mb-3' style={{borderBottom: '1px solid #d7d7d7'}}>
                    <button type="button" className="btn btn-primary btn-lg text-white" onClick={handlePrint}>
                        Click to Print
                    </button>
                </div>
            }

            <div style={{display: 'none'}}>
                <div ref={componentRef}>
                    <div dangerouslySetInnerHTML={{__html: printContent}}></div>
                </div>
            </div>
        </div>
    );
}
