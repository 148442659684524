import React, {useContext, useEffect, useState} from "react";
import URL from "../_services/routes";
import Api from "../_services/api_service";
import {GlobalContext} from "../_services/global-context";
import {Button, Col, Container, Row, Table} from "react-bootstrap";
import {DashCircle, PencilSquare, PlusCircle, XCircle} from "react-bootstrap-icons";
import Swal from "sweetalert2";
import Services from "../_services/services";

export default function Cart() {
    const [global, dispatch] = useContext(GlobalContext);
    const [orderId, setOrderId] = useState(global.order_id);
    const [searchValues, setSearchValues] = useState({});
    const [cartDetails, setCartDetails] = useState([]);
    const [cartSummery, setCartSummery] = useState({});

    const [errors, setErrors] = useState({});

    // Effects
    useEffect(() => {

        if (global.order_id) {
            handleLoadCartData()
        }else {
            setCartDetails([])
            setCartSummery([])
        }


    }, [global.order_id])

    const handleLoadCartData = () => {
        const params = {
            pcl_id: global.pcl.id,
        }

        const qryParams = new URLSearchParams(params).toString()
        Api.get(`client/orders/${orderId}?` + qryParams, (res) => {
            const data = res.data.data

            setCartDetails(data.cart_details || [])
            setCartSummery(data.cart_summery || {})
            if (data?.customer?.id) {
                dispatch({type: 'ORDER-CUSTOMER', payload: data.customer.id});
            }
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    const handleUpdateCartItem = (cart_id, item_id) => {
        Swal.fire({
            title: '',
            html: "<b>Are you sure to edit this item?</b>",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#b35322',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                // client/order-details/${data_id}
                window.location.assign('/cart/update?order_detail_id='+cart_id+'&item_id='+item_id)
            }
        })
    }

    const handleRemoveCartItem = (data_id) => {
        const data = {
            pcl_id: global.pcl.id,
            order_id: orderId,
        }

        Swal.fire({
            title: '',
            html: "<span>Are you sure to <span class='fw-bold text-danger'>Remove</span> this item?</span>",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#b35322',
            confirmButtonText: 'Yes, Remove'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.delete(`client/order-details/${data_id}`, {data: data}, (res) => {
                    const data = res.data.data
                    dispatch({type: 'CART-ITEMS-COUNT', 'payload': Number(global.cart_items_count) - 1})
                    setCartDetails(data.cart_details || [])
                    setCartSummery(data.cart_summery || {})
                }, (errors) => {
                    dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
                })
            }
        })
    }

    const handleCreateNewOrder = (button = 'new') => {
        const message = button === 'new' 
            ? "<span>Do you want to close this order and <span class='fw-bold text-danger'>open</span> a new order?</span>"
            : "<span>Are you sure to <span class='fw-bold text-danger'>Remove all items</span> from the cart?</span>"

        const button_label = button === 'new' ? "Yes" : "Yes, Remove"

        Swal.fire({
            title: '',
            html: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#b35322',
            confirmButtonText: button_label
        }).then((result) => {
            if (result.isConfirmed) {
                if (button === 'new') {
                    setCartDetails([])
                    setCartSummery({})
                    setOrderId(null)
                    dispatch({type: "ORDER-ID", payload: ''})
                    dispatch({type: 'CHECKOUT-TIME', payload: ''})
                }else if (button === 'clear') {
                    // handleClearCart()
                }
            }
        })
    }

    const handleClearCart = () => {
        const data = {
            pcl_id: global.pcl.id,
        };

        Api.put(`client/orders/${orderId}/clear`, data, (res) => {
            const data = res.data.data
            setCartDetails([])
            setCartSummery({})
            dispatch({type: 'CHECKOUT-TIME', payload: ''})
            dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    const handleApplyPromo = () => {
        const data = {
            pcl_id: global.pcl.id,
            promo_code: searchValues.promo_code || '',
        }

        if (!searchValues.promo_code || searchValues.promo_code === '' || searchValues.promo_code === ' ') {
            errors['promo_code'] = 'Please enter a valid promo code.'
            setErrors({...errors})
            return false;
        }

        Swal.fire({
            title: '',
            html: `<span>Are you sure to apply <span class="fw-bold">${searchValues.promo_code}</span> promo code?</span>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#b35322',
            confirmButtonText: 'Yes, Proceed'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.put(`client/orders/${orderId}`, data, (res) => {
                    const data = res.data.data
                    setErrors({})
                    setSearchValues({})
                    setCartDetails(data.cart_details || [])
                    setCartSummery(data.cart_summery || {})

                    dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
                }, (errors) => {
                    dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
                })
            }
        })
    }

    // handleItemQuantity(cart.id, 'add')
    const handleCartItemQuantity = (order_detail_id, quantity, type) => {
        let data = {
            pcl_id: global.pcl.id, order_id: orderId,
            quantity: quantity,
        }

        Api.put(`client/order-details/quantity/${order_detail_id}`, data, (res) => {
            const data = res.data.data
            setCartDetails(data.cart_details || [])
            setCartSummery(data.cart_summery || {})

            if (type === 'add') {
                dispatch({type: 'CART-ITEMS-COUNT', 'payload': Number(global.cart_items_count) + 1})
            } else if (type === 'remove') {
                dispatch({type: 'CART-ITEMS-COUNT', 'payload': Number(global.cart_items_count) - 1})
            }
            // dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    const handleDeleteDiscount = (data_id) => {
        const data = {
            pcl_id: global.pcl.id,
            order_id: orderId,
        }

        return false;
        Api.delete(`client/order-details/${data_id}`, {data: data},
            (res) => {
                const data = res.data.data
                setCartDetails(data.cart_details || [])
                setCartSummery(data.cart_summery || {})
                dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
            }, (errors) => {
                dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
            })
    }

    const handleCheckoutClick = (checkout = 'complete') => {
        dispatch({type: 'SHIPPING-ADDRESS', payload: false});
        localStorage.setItem('checkout_type', checkout)
        window.location.assign('/checkout?rd=/cart')
    }

    let total_amount = 0;
    let total_quantity = 0;
    let sub_total_amount = 0;

    let tax1_name = null;
    let tax2_name = null;
    let total_tax1 = 0;
    let total_tax2 = 0;

    // console.log(cartDetails)
    return (
        <div className=''>
            <Container className='bg-white min-vh-100'>
                {/* Cart title */}
                <div className='d-flex flex-wrap justify-content-between align-items-end gap-2 mb-2 border-bottom pb-1'>
                    <div className='fw-bold fs-21x text-light-green hide-on-mobile'>CART DETAILS (Order # {cartSummery.order_number})</div>
                    <div className="d-flex">
                        <a href='/' className='btn btn-sm btn-success'>Continue Shopping</a>
                        {(global.order_id && cartDetails.length > 0) &&
                            <a className='btn btn-sm btn-primary ms-2' onClick={() => handleCreateNewOrder('new')}>New Order</a>
                        }

                        {/*{(cartDetails.length > 0) &&*/}
                        {/*    <a className='btn btn-sm btn-warning ms-2' onClick={() => handleCreateNewOrder('clear')}>Clear Cart</a>*/}
                        {/*}*/}
                    </div>
                    <div className='hide-on-desktop flex-1'>
                        {/* Cart checkout payment button */}
                        <div className='d-flex justify-content-center'>
                            {(cartSummery?.required_deposit > 0) &&
                                <button className='btn btn-warning w-100 me-2' onClick={() => handleCheckoutClick('deposit')}>
                                    +DPT ${Number(cartSummery.required_deposit).toFixed(2)}</button>
                            }
                            <button className='btn btn-primary w-100' onClick={() => handleCheckoutClick()}>
                                CHECKOUT ${Number(cartSummery.balance_amount).toFixed(2)}</button>
                        </div>
                    </div>

                </div>

                {/* Cart Table */}
                <Row>
                    <Col md={9}>

                        {/* Cart list view */}
                        <div className='cart-list'>
                            {cartDetails.map((cart, index) => {
                                const order_detail_id = cart.id;
                                if (cart.order_detail_id != null) return null;


                                const sub_price = cart.quantity * cart.applied_price;

                                sub_total_amount += sub_price;
                                total_amount += sub_price;
                                total_quantity += cart.quantity;

                                total_tax1 += (cart.applied_tax1_rate * cart.applied_price) / 100

                                total_amount = Number(total_amount.toFixed(2));
                                sub_total_amount = Number(sub_total_amount.toFixed(2));

                                let property_total = 0;
                                const properties_show = cartDetails.find(obj => obj.order_detail_id == order_detail_id)
                                return (
                                    <div key={index} className='cart-list-item'>
                                        <Row>
                                            <Col className='col-12 col-md-4 mb-1'>
                                                <div className='fw-bold mb-2'>
                                                    {cart.item_name}
                                                    <div className='fw-normal fs-13x ms-2'>{cart.descriptive_properties}</div>
                                                </div>
                                            </Col>
                                            <Col className='col-3 col-md-2 mb-1'>
                                                <div className='text-end me-3'>
                                                    <div className={`text-light-green`}>
                                                        {(cart.prices && cart.prices.length > 0) ?
                                                            <>
                                                                <span className={`text-secondary ${(cart.prices.length > 0) && 'text-decoration-line-through'}`}>${parseFloat(cart.regular_price.toFixed(2))}</span>
                                                                &ensp;
                                                                (${parseFloat(cart.applied_price.toFixed(2))})
                                                            </>
                                                            : <>${parseFloat(cart.applied_price.toFixed(2))}</>
                                                        }

                                                    </div>

                                                    {(cart.prices && cart.prices.length > 0) &&
                                                        cart.prices.map((price) => {
                                                            return (
                                                                <span key={cart.id}>
                                                                    {(price.price_id != null || price.discount_price != null) &&
                                                                        <div className='d-flex justify-content-between'
                                                                             style={{padding: '1px 5px', fontSize: '13px', marginTop: '1px', backgroundColor: '#F3D25C'}}>
                                                                            <span style={{lineHeight: '22px', marginRight: '4px'}}>
                                                                                ${parseFloat(price.discount_price.toFixed(2))}
                                                                                {(price.discount_type !== 'exact_discount') &&
                                                                                    <span style={{color: 'black'}}>({price.discount}%) </span>
                                                                                } off
                                                                                {/*<span style={{color: '#c14d4d'}}>({price.discount_title.substring(0, 60)})</span>*/}
                                                                            </span>
                                                                            <span
                                                                                className='fs-15x text-danger cursor-pointer'
                                                                                data-tooltip-id='tooltip'
                                                                                data-tooltip-content='Remove discount'
                                                                                onClick={() => handleDeleteDiscount(price.odAp_id)}>
                                                                              <i className="fa-solid fa-circle-xmark"></i>
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Col>
                                            <Col className='col-3 col-md-2 mb-1'>
                                                <div className='d-flex justify-content-end'>
                                                    <div className='d-flex justify-content-between item-quantity' style={{width: '82px'}}>
                                                    <span className={`quantity-icon cursor-pointer ${(cart.type === "booking_item") ? 'text-danger' : 'text-danger'}`}
                                                        onClick={(e) => handleCartItemQuantity(cart.id, Number(cart.quantity) - 1, 'remove')}><DashCircle/></span>
                                                        <div className='ps-1 pe-1'>{cart.quantity}</div>
                                                        <span className={`quantity-icon cursor-pointer ${(cart.type === "booking_item") ? 'text-success' : 'text-success'}`}
                                                            onClick={(e) => handleCartItemQuantity(cart.id, Number(cart.quantity) + 1, 'add')}><PlusCircle/></span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className='col-3 col-md-2 mb-1'>
                                                <div className='text-light-green text-end me-2'>${sub_price.toFixed(2)}</div>
                                            </Col>
                                            <Col className='col-3 col-md-2 mb-1'>
                                                <div className='d-flex justify-content-end gap-2'>
                                                    {(cart.type == "booking_item") &&
                                                        <div className='text-success fs-18x cursor-pointer'
                                                             onClick={() => handleUpdateCartItem(cart.id, cart.item_id)}>
                                                            <PencilSquare/></div>
                                                    }

                                                    <div className='text-danger fs-18x cursor-pointer'
                                                         onClick={() => handleRemoveCartItem(cart.id)}>
                                                        <XCircle/>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        {/* Item sub properties list */}
                                        {(properties_show) &&
                                            <div className='mt-2 fs-15x'>
                                                <div className='section-heading-1 mb-1'></div>

                                                {cartDetails.map((prop, p_index) => {
                                                    if (prop.order_detail_id == order_detail_id) {
                                                        const sub_price = prop.quantity * prop.applied_price;
                                                        property_total += sub_price;
                                                        total_amount = total_amount +  sub_price;
                                                        sub_total_amount = sub_total_amount +  sub_price;
                                                        return (
                                                            <div key={p_index} className='cart-property-item border-bottom'>
                                                                <Row>
                                                                    <Col className='col-12 col-md-4 mb-1'>
                                                                        <div className=''>
                                                                            {(prop.display_property_value_with_title == 1) &&
                                                                                <span class='fw-bold'> {prop.item_name}: </span>
                                                                            }
                                                                            {prop.property_value}
                                                                        </div>
                                                                    </Col>
                                                                    <Col className='col-3 col-md-2 mb-1'>
                                                                        <div className='text-light-green text-end me-3'>${prop.applied_price}</div>
                                                                    </Col>
                                                                    <Col className='col-3 col-md-2 mb-1'>
                                                                        <div className='d-flex justify-content-end'>
                                                                            <div className='d-flex justify-content-center item-quantity' style={{width: '82px'}}>
                                                                                <div className='ps-1 pe-1'>{prop.quantity}</div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col className='col-3 col-md-2 mb-1 text-end'>
                                                                        <div className='text-light-green me-2'>${sub_price}</div>
                                                                    </Col>
                                                                    <Col className='col-3 col-md-2 mb-1'></Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        }
                                    </div>
                                )
                            })}

                            {(cartDetails.length === 0)&& <div className='cart-no-item mb-4'>Cart is empty!</div>}
                        </div>

                    </Col>
                    <Col md={3}>

                        <Table bordered className='' size={'sm'}>
                            <thead className='cart-td-bg-light-green'>
                            <tr>
                                <td colSpan={2} className='text-white fw-bold fs-17x'>Payment Summary</td>
                            </tr>
                            </thead>
                            <tbody>

                            {(cartDetails.length >= 0) &&
                                <>
                                    <tr>
                                        <td className='fw-bold w-50'>Subtotal</td>
                                        <td className='text-end price-cell'>
                                            <div
                                                className='price-text'>${Number(cartSummery.sub_total).toFixed(2)}</div>
                                        </td>
                                    </tr>

                                    {(cartSummery.tax1_name) &&
                                        <tr>
                                            <td className='fw-bold w-50'>{cartSummery.tax1_name}</td>
                                            <td className='text-end price-cell'>
                                                <div
                                                    className='price-text'>${Number(cartSummery.tax1_total).toFixed(2)}</div>
                                            </td>
                                        </tr>
                                    }

                                    {(cartSummery.tax2_name) &&
                                        <tr>
                                            <td className='fw-bold w-50'>{cartSummery.tax2_name}</td>
                                            <td className='text-end price-cell'>
                                                <div
                                                    className='price-text'>${Number(cartSummery.tax2_total).toFixed(2)}</div>
                                            </td>
                                        </tr>
                                    }

                                    <tr>
                                        <td className='fw-bold w-50'>Total</td>
                                        <td className='text-end price-cell'>
                                            <div
                                                className='price-text'>${Number(cartSummery.grand_total).toFixed(2)}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='fw-bold w-50'>Amount Paid</td>
                                        <td className='text-end price-cell'>
                                            <div
                                                className='price-text'>${Number(cartSummery.total_payment).toFixed(2)}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className='fw-bold w-50'>Balance Due</td>
                                        <td className='text-end price-cell'>
                                            <div
                                                className='price-text'>${Number(cartSummery.balance_amount).toFixed(2)}</div>
                                        </td>
                                    </tr>
                                </>
                            }

                            {(!cartSummery.grand_total) &&
                                <tr>
                                    <td className='fw-bold w-50'>Total</td>
                                    <td className='fw-bold text-center'>$0</td>
                                </tr>
                            }
                            </tbody>
                        </Table>

                        {/* Promo Section */}
                        <div className='mt-2 mb-3'>
                            <div className='mb-2'>
                                <input type={"text"} name='promo_code'
                                       className={`form-control ${(errors.promo_code) && 'is-invalid'}`}
                                       onChange={(e) => {
                                           searchValues['promo_code'] = e.target.value
                                           setSearchValues({...searchValues})
                                       }}
                                       value={searchValues.promo_code || ''}
                                disabled={(cartSummery.balance_amount <= 0)}/>
                                <div className="invalid-feedback">{errors.promo_code}</div>
                            </div>

                            <div>
                                <Button variant='success' className={`w-100 ${(cartSummery.balance_amount <= 0 ? 'disabled' : '')}`} onClick={() => handleApplyPromo()}>
                                    Apply Promo</Button>
                            </div>
                        </div>

                        {/* Cart checkout payment button // !global.checkout_time_passed */}
                        {(cartSummery.balance_amount > 0) &&
                        <div className='d-flex justify-content-center'>
                            {(cartSummery?.required_deposit > 0) &&
                                <button className='btn btn-warning w-100 me-2' onClick={() => handleCheckoutClick ('deposit')}>
                                    +DPT ${Number(cartSummery.required_deposit).toFixed(2)}</button>
                            }
                            <button className='btn btn-primary w-100' onClick={() => handleCheckoutClick()}>
                                CHECKOUT ${Number(cartSummery.balance_amount).toFixed(2)}</button>
                        </div>
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}