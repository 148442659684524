import React, {useEffect, useState} from "react";
import Services from "./modules/_services/services";
import {GlobalContextProvider} from "./modules/_services/global-context";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import './App.css';
import './style.css';
import URL from "./modules/_services/routes";
import BaseStructure from "./base_structure";
import NotFound from "./modules/not-found";
import Login from "./modules/auth/login";
import Register from "./modules/auth/register";
import KioskWaiver from "./modules/kiosk/kiosk_waiver";
import Waiver from "./modules/waiver/waiver";
import WaiverSign from "./modules/waiver/waiver_sign";
import Waivers from "./modules/profile/waivers";

import Logout from "./modules/auth/logout";
import POS from "./modules/pos/pos";
import ResetPassword from "./modules/auth/reset_password";
import Cart from "./modules/pos/cart";
import CartItemProperties from "./modules/pos/cart_item_properties";
import Checkout from "./modules/checkout";
import ThankYou from "./modules/thank_you";
import ShippingAddress from "./modules/shipping_address";
import PaymentConfirmation from "./modules/payment_confirmation";
import Account from "./modules/profile/account";
import moment from 'moment-timezone';
import Orders from "./modules/profile/orders";
import RsvpInvitations from "./modules/rsvp/rsvp_invitations";
import RsvpResponse from "./modules/rsvp/rsvp_response";
import Rsvp from "./modules/rsvp/rsvp";
import latestTag from './latest_tag.json';
import PrintTicketsView from "./modules/prints/print_tickets_view";

function App() {

    const initialGlobalState = {
        pcl: Services.getPcl(),
        user: Services.getUser(),
        token: Services.getToken(),
        source: Services.getSource(),
        order_id: Services.getOrderId(),
        cart_items_count: Services.getCartItemsCount(),

        order_time: Services.getOrderTime(),
        checkout_time: Services.getCheckoutTime(),
        checkout_time_passed: Services.getCheckoutTimePassed(),
        order_customer: Services.getOrderCustomer(),
        requested_page: Services.getRequestedPage(),
        shipping_address: Services.getShippingAddress(),

        location: Services.getLocation(),
        returning_url: Services.getReturningUrl()
    }

    const accessType = URL.accessType()
    const location = JSON.parse(localStorage.getItem('location'))
    const DEFAULT_TIMEZONE = (location) ? location.timezone || '' : ''; // America/Toronto
    moment.tz.setDefault(DEFAULT_TIMEZONE);

    // localStorage.getItem('location')
    useEffect(() => {
        const location = JSON.parse(localStorage.getItem('location'))
        if (location && location.timezone) {
            const DEFAULT_TIMEZONE = (location) ? location.timezone || '' : ''; // America/Toronto
            moment.tz.setDefault(DEFAULT_TIMEZONE);
        }
    }, [localStorage.getItem('location')]);


    let option_classes = 'container main-content-container'
    if (accessType === 'login'){
        option_classes = '';
    }

    return (
        <div className="App d-grid">
            <div className={`dotted-background`}>
                <GlobalContextProvider init={initialGlobalState}>
                    <BrowserRouter>
                        <BaseStructure/>
                        <div className={`${option_classes}`}>
                            <Routes>
                                {/* Kiosk */}
                                <Route path="/kiosk" Component={KioskWaiver}/>
                                <Route path="/kiosk/waiver-sign" Component={WaiverSign}/>
                                <Route path="/kiosk/login" Component={Login}/>
                                <Route path="/kiosk/register" Component={Register}/>
                                <Route path="/kiosk/reset-password" Component={ResetPassword}/>

                                {/* Kiosk */}
                                <Route path="/" Component={POS}/>
                                <Route path="/pos/*" Component={POS}/>
                                <Route path="/cart" Component={Cart}/>
                                <Route path="/cart/add" Component={CartItemProperties}/>
                                <Route path="/cart/update" Component={CartItemProperties}/>
                                <Route path="/checkout" Component={Checkout}/>
                                <Route path="/shipping-address" Component={ShippingAddress}/>

                                <Route path="/prints" Component={PrintTicketsView}/>

                                <Route path="/waiver" Component={Waiver}/>
                                <Route path="/waiver-sign" Component={WaiverSign}/>

                                <Route path="/my-orders" Component={Orders}/>
                                <Route path="/my-waivers" Component={Waivers}/>
                                <Route path="/my-account" Component={Account}/>
                                <Route path="/my-profile" Component={Account}/>

                                <Route path="/login" Component={Login}/>
                                <Route path="/register" Component={Register}/>
                                <Route path="/not-found" Component={NotFound}/>
                                <Route path="/Logout" Component={Logout}/>
                                <Route path="/reset-password" Component={ResetPassword}/>

                                {/* RSVP links */}
                                <Route path="/rsvp" Component={Rsvp}/>
                                <Route path="/rsvp-invitations" Component={RsvpInvitations}/>
                                <Route path="/invitation/response" Component={RsvpResponse}/>

                                <Route path="/thank-you" Component={ThankYou}/>
                                <Route path="/payment-confirmation" Component={PaymentConfirmation}/>
                            </Routes>
                        </div>
                        <div className="text-center text-disabled small">{latestTag.tag}</div>    
                    </BrowserRouter>
                </GlobalContextProvider>
            </div>
        </div>
    )
}

export default App;
