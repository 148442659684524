import React, {useContext, useEffect, useState} from "react";
import URL from "./modules/_services/routes";
import Api from "./modules/_services/api_service";
import {GlobalContext} from "./modules/_services/global-context";
import TopBar from "./modules/layout/topbar";
import TopNavManu from "./modules/layout/navbar";
import GlobalAlert from "./modules/_services/global-alert";
import Services from "./modules/_services/services";
import {Tooltip} from "react-tooltip";
import moment from "moment-timezone";
import {RequestLoader} from "./modules/_services/request_loader";
import data from "bootstrap/js/src/dom/data";

function BaseStructure() {
    const [global, dispatch] = useContext(GlobalContext)
    const [cart, setCart] = useState({});

    const base_url = URL.baseUrl();
    const accessType = URL.accessType()
    const ref = URL.urlQueryParams('ref');
    const source = URL.urlQueryParams('source');
    const redirect = URL.urlQueryParams('rd');

    // Auth links
    const current_link = accessType.replace(/^\/|\/$/g, '');
    const auth_links = ['my-account', 'my-waivers', 'my-orders'];
    useEffect(() => {
        if (base_url) handleVerifyBaseURL(base_url);
        if ((global.token && global.token.trim() !== '') && global.user?.id) {
            handleVerifyLogin();

            // !global.order_customer &&
            if (global.order_id && global.user.id) {
                handleCheckOrderCustomer();
            }

            if (ref && ref === 'email') {
                handleClearLoginDetails();
            }
        }

        // Check if customer not valid for order's attached customer
        if (global.order_customer && global.order_id && (!global.user || global.user?.id != global.order_customer)) {
            dispatch({type: 'ORDER-ID', payload: ''});
            dispatch({type: 'ORDER-TIME', payload: ''});
            dispatch({type: 'CHECKOUT-TIME', payload: ''});
            dispatch({type: 'CART-ITEMS-COUNT', payload: 0});
            dispatch({type: 'ORDER-CUSTOMER', payload: ''});
            Services.redirectTo('/', global.source)
        }

        dispatch({type: 'SOURCE', payload: source || 'client'})
        dispatch({type: 'RETURNING-URL', payload: redirect || ''})
        handleLoadLocation()
    }, [base_url, global.pcl.location_id]);


    useEffect(() => {
        if (global?.pcl?.id) {
            if (global?.order_id) {
                handleLoadCartCount();
            } else {
                dispatch({type: 'CART-ITEMS-COUNT', 'payload': 0})
            }
        }
    }, [global.order_id, global.cart_items_count]);

    const handleVerifyBaseURL = (base_url) => {
        Api.get(`companies/search-by-base-admin-url?base_admin_url=${base_url}`, (res) => {
            const pcl = res.data.data
            //Set favicon
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = pcl.favicon;
            dispatch({type: 'PCL', 'payload': pcl})
        }, (errors) => {
            // window.location.href = "/not-found";
        });
    }

    // Verify login token
    const handleVerifyLogin = () => {
        const params = {
            pcl_id: global.pcl.id,
            user_id: global.user.id,
        }

        const qParams = new URLSearchParams(params).toString();
        Api.get(`auth/verify?${qParams}`, (res) => {
            // User authentication verified
            // const pcl = res.data.data
        }, (errors) => {
            // If login or auth token not valid or expired
            handleClearLoginDetails();
            dispatch({type: "SHOW-ERROR", payload: 'Login details not authenticated!'});

            let redirect_base = "/"
            if (URL.accessType() === 'kiosk') redirect_base = "/kiosk"
            window.location.assign(redirect_base);
        });
    }

    const handleClearLoginDetails = () => {
        dispatch({type: 'USER', payload: {}});
        dispatch({type: 'TOKEN', payload: ''});
        dispatch({type: 'ORDER-ID', payload: ''})
        dispatch({type: 'ORDER-TIME', payload: ''})
        dispatch({type: 'CHECKOUT-TIME', payload: ''})
        dispatch({type: 'CART-ITEMS-COUNT', payload: 0})
        dispatch({type: 'ORDER-CUSTOMER', payload: ''});
    }


    const handleLoadCartCount = () => {
        const params = `?pcl_id=${global.pcl.id}&order_id=${global.order_id}`
        Api.get(`client/order-details/cart-count${params}`, (res) => {
            const count = res.data.data
            dispatch({type: 'CART-ITEMS-COUNT', payload: count})

            if (count == 0) {
                // dispatch({type: 'ORDER-ID', payload: ''})
            }
        }, (errors) => {
            // window.location.href = "/not-found";
        });
    }

    const handleLoadLocation = () => {
        if (global.pcl?.location_id) {
            Api.get(`client/locations/${global.pcl.location_id}`, (res) => {
                const data = res.data.data
                if (data.timezone) {
                    moment.tz.setDefault(data.timezone);
                }

                dispatch({type: 'LOCATION', 'payload': data})
            }, (errors) => {
                // window.location.href = "/not-found";
            });
        }
    }

    const handleCheckOrderCustomer = () => {
        const req_param = {
            'pcl_id': global.pcl.id
        }
        Api.put(`client/orders/${global.order_id}/customer`, req_param, (res) => {
            const data = res.data.data;
            if (data.customer_id) {
                dispatch({type: 'ORDER-CUSTOMER', payload: data.customer_id ?? 0});
            }
        }, (errors) => {
            // window.location.href = "/not-found";
        });
    }


    // Auth pages
    if (auth_links.includes(current_link) && (!global.token || !global.user || !global.user.id)) {
        Services.redirectTo('/', global.source);
    }

    // Show topBar and menu
    let show_header = (accessType !== 'kiosk' && redirect !== 'kiosk' && redirect !== '/kiosk')
    // console.log('accessType: ', accessType)
    // accessType !== 'waiver'
    return (
        <div>
            <GlobalAlert/>
            {(show_header) &&
                <>
                    <TopBar/>
                    <TopNavManu />
                </>
            }

            <Tooltip id='tooltip' style={{backgroundColor: '#62676a', padding: '3px 7px'}}/>
            <div className="" style={{display: 'none'}} id='request_loader'>
                <div className="request-loader">
                    <RequestLoader/>
                </div>
            </div>
        </div>
    );
}

export default BaseStructure;
